<template lang="pug">
  v-container#pending-accounts(fluid='' tag='section')
    v-card(v-if="!userStatus && !selectedPatient")
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title Cuentas pendientes
          v-btn(icon="" dark="" @click="exitPendigAccDialog" style="position: absolute; top: 0px; right: 10px;")
            v-icon.primary.rounded mdi-close
    v-card
      v-col(cols="12" md="12" style='padding-top:0px;')
        v-text-field(v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details style='margin: 10px 20px 20px 20px;')
        v-skeleton-loader(:loading="cargando" type="table" v-if="viewProgressBar")
        v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!viewProgressBar" :items="pendingArr" item-key="id" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando Datos" :sort-by="ordenarPor" calculate-widths @current-items="getFiltered" :footer-props="{\'items-per-page-text':'Pacientes por página'\}")
</template>
<script>
  var accounting = require('accounting')
  export default {
    components: {
    },
    data: () => ({
      search: '',
      pendingLstFilter: [],
      pendingArr: [],
      cargando: false,
      headers: [
        { text: 'Nombre', value: 'name', align: 'left', filterable: true },
        { text: 'Apellido', value: 'lastname', align: 'left', filterable: true },
        { text: 'Teléfono', value: 'telf', align: 'left', filterable: true },
        { text: 'Celular', value: 'cellPhone', align: 'center', filterable: true },
        { text: 'Tratamientos', value: 'numTreats', align: 'center', filterable: true },
        { text: 'Total', value: 'totalCost', align: 'center', filterable: true },
        { text: 'Pagado', value: 'totalPaid', align: 'center', filterable: true },
        { text: 'Deuda', value: 'totalRecidue', align: 'center', filterable: true },
      ],
    }),
    computed: {
      lstReceiptsId () {
        return this.$store.state.treatment.lstReceiptsId
      },
      patientsAccLst () {
        return this.$store.state.patients.allAccounts
      },
      viewProgressBar () {
        return this.$store.state.patients.viewProgressBar
      },
      lstTreatments () {
        return this.$store.state.treatment.lstTreatments
      },
    },
    watch: {
      patientsAccLst () {
        this.viewProgressBar = false
        this.cargando = false
        this.pendingLstFilter = this.pendingArr
      },
    },
    created () {
      this.createTableElements()
    },
    methods: {
      async createTableElements () {
        // proceso de lectura de todas las cuentas para calcular el total bruto
        for (let i = 0; i < this.patientsAccLst.length; i++) {
          let numTreats = 0
          const patientId = this.patientsAccLst[i].id

          await this.$store.dispatch('patients/loadPatientData', patientId).then(async patientCharged => {
            // para que solo cargue los datos de los pacientes existentes y evitar que de error por pacientes que fueron borrados por bug que duplica pacientes
            if (patientCharged.data) {
              let totalRecidueToPay = 0.00
              let totalPaid = 0.00
              let totalCost = 0.00
              for (let j = 0; j < this.patientsAccLst[i].patientData.length; j++) {
                let treatCostWithDisc = 0.00
                let treatCostWithouDisc = 0.00
                let treatDiscount = 0.00
                // id del tratamiento guardado en cuentas del paciente
                const treatId = this.patientsAccLst[i].patientData[j].finanData.treatmentBelongId

                // se carga el descuento del tratamiento
                await this.$store.dispatch('patients/loadTreatmentData', [patientId, treatId]).then(async treatmentData => {
                  // para que se cargue solo el tratamiento aprobado y no las cuentas de paciente creada desde proformas
                  if (treatmentData.treatmentKey && this.patientsAccLst[i].patientData[j].finanData.finanProcessRows) {
                    numTreats++

                    for (let k = 0; k < this.patientsAccLst[i].patientData[j].finanData.finanProcessRows.length; k++) {
                      const processTotalCost = parseFloat(this.patientsAccLst[i].patientData[j].finanData.finanProcessRows[k].totalCost)
                      treatCostWithouDisc = accounting.toFixed(parseFloat(treatCostWithouDisc) + processTotalCost, 2)
                    }

                    // NO BORRAR IDS DE CUENTAS DEL PACIENTE PORQUE PUEDEN SER CUENTAS DE PROFORMAS
                    // solo se incluye descuento en los calculos cuando este existe
                    if (treatmentData.descuento) {
                      if (treatmentData.dsctoType === '$') {
                        treatDiscount = parseFloat(treatmentData.descuento)
                      } else {
                        treatDiscount = accounting.toFixed(parseFloat(treatCostWithouDisc) * parseFloat(treatmentData.descuento / 100), 2)
                      }
                    }
                    treatCostWithDisc = accounting.toFixed(parseFloat(treatCostWithouDisc) - parseFloat(treatDiscount), 2)
                    totalCost = accounting.toFixed(parseFloat(totalCost) + parseFloat(treatCostWithDisc), 2)

                    // si es que el tratamiento ya tiene recibos creados
                    if (treatmentData.receiptIds) {
                      // se carga los datos del array de ids de los recibos que estan dentro del nodo de tratamientos de la bd
                      await this.$store.dispatch('treatment/loadReceiptsIds', [patientId, treatId])
                      if (this.lstReceiptsId.length > 0) {
                        // se cargan los recibos correspondientes al tratamiento seleccionado
                        for (let l = 0; l < this.lstReceiptsId.length; l++) {
                          await this.$store.dispatch('financial/loadReceipt', [patientId, this.lstReceiptsId[l]]).then(resReceiptsLoaded => {
                            totalPaid = accounting.toFixed(parseFloat(totalPaid) + parseFloat(resReceiptsLoaded.totalToPay), 2)
                          })
                        }
                      } else {
                        totalPaid = 0.00
                      }
                    }
                    // se guarda en el nodo de cuentas del paciente: el saldo, valor total pagado hasta el momento, valor con descuento, descuento total y el tipo de descuento de ese tratamiento
                    // await this.$store.dispatch('patients/saveTotalValues', [patientId, patientAccId, totalRecidueToPay, totalPaid, treatCostWithDisc, treatDiscount, treatmentData.dsctoType, treatCostWithouDisc])
                  }
                })
              }
              totalRecidueToPay = accounting.toFixed(parseFloat(totalCost) - parseFloat(totalPaid), 2)
              // se construye la fila de la tabla
              const newPendingRow = {
                name: patientCharged.data.names,
                lastname: patientCharged.data.lastNames,
                cellPhone: patientCharged.data.phone2,
                numTreats: numTreats,
                telf: patientCharged.data.phone1,
                totalCost: totalCost,
                totalPaid: totalPaid,
                totalRecidue: '$ ' + totalRecidueToPay,
              }
              this.pendingArr.push(newPendingRow)
            }
          })
        }
      },
      exitPendigAccDialog () {
        this.$emit('exit-pending')
      },
    },
  }
</script>
<style>
</style>
